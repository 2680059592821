import React from 'react';
import { PageContent } from '@core/ui';
import { TableProvider } from '@core/table';

import { PartnerBoxesTableContext } from '../contexts';
import { usePartnerBoxesTableContext } from '../hooks';
import { PartnerBoxesTable, PartnerBoxesActionBar } from '../containers';

export const PartnerBoxesPage = () => {
  const { onFetch } = usePartnerBoxesTableContext();
  return (
    <TableProvider name='partner-boxes' context={PartnerBoxesTableContext} onFetch={onFetch}>
      <PageContent $contain={true}>
        <PartnerBoxesActionBar />
        <PartnerBoxesTable />
      </PageContent>
    </TableProvider>
  );
};

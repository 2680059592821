import * as React from 'react';
import { TableFetchParams, tableQueryMaker } from '@core/table';
import { useService } from '@core/inversify-react';

import { GetPartnerBoxesRepoType, IGetPartnerBoxesRepo } from '../../repos';

export const usePartnerBoxesTableContext = () => {
  const getPartnerBoxesRepo = useService<IGetPartnerBoxesRepo>(GetPartnerBoxesRepoType);

  const onFetch = React.useCallback(
    async (params: TableFetchParams) => {
      const result = await getPartnerBoxesRepo.execute(tableQueryMaker(params));

      if (result.status === 200) {
        return {
          data: result.response.data,
          total: result.response.total,
        };
      } else {
        throw new Error(result.response);
      }
    },
    [getPartnerBoxesRepo],
  );

  return { onFetch };
};

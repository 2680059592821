import { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Button, Dropdown, Menu, Modal, message } from 'antd';
import * as Icons from '@ant-design/icons';
import { StopPropagation } from '@core/ui';
import { tableColumns } from '@core/table';

import { IPartnerBox, IRemovePartnerBoxesRepo, RemovePartnerBoxesRepoType, partnerBoxQueryKeys } from '../../';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { useService } from '@core/inversify-react';
import { useBus } from '@core/event-bus';
import { useAuth } from '@modules/auth';

export const usePartnerBoxesTableColumns = () => {
  const history = useHistory();
  const location = useLocation();
  const { publish } = useBus();
  const { can } = useAuth();
  const removePartnerBoxesRepo = useService<IRemovePartnerBoxesRepo>(RemovePartnerBoxesRepoType);
  const actionsColumn = useMemo<Column<IPartnerBox>>(
    () => ({
      ...tableColumns.actions,
      Cell: ({ row: { original } }) => {
        const [visible, setVisible] = useState<boolean>(false);

        const openEdit = useCallback(() => {
          history.push(generatePath('/@next/partner-boxes/:id/update', { id: original.id }), { background: location });
        }, [original.id]);

        const remove = useCallback(() => {
          const onOk = async () => {
            const result = await removePartnerBoxesRepo.execute([original.id]);

            if (result.status === 200) {
              publish({ type: '@partner-boxes/remove/succeed', payload: [original.id] });
            } else {
              message.error(result.response);
            }
          };
          Modal.confirm({ title: 'Diqqət', content: 'Yeşiyi silməyə əminsinizmi?', onOk });
        }, [original.id]);

        const overlay = (
          <Menu onClick={() => setVisible(false)}>
            {can('branch_manager') && (
              <>
                <Menu.Item onClick={openEdit} icon={<Icons.EditOutlined />}>
                  Düzəliş et
                </Menu.Item>
                <Menu.Item onClick={remove} icon={<Icons.DeleteOutlined />}>
                  Sil
                </Menu.Item>
              </>
            )}
          </Menu>
        );
        return (
          <StopPropagation>
            <Dropdown visible={visible} onVisibleChange={setVisible} overlay={overlay}>
              <Button icon={<Icons.MoreOutlined />} size='small' />
            </Dropdown>
          </StopPropagation>
        );
      },
    }),
    [history, location, publish, removePartnerBoxesRepo, can],
  );

  const baseColumns = useMemo<Column<IPartnerBox>[]>(
    () => [
      {
        ...tableColumns.small,
        accessor: (row) => row.id,
        id: partnerBoxQueryKeys.id,
        Header: 'Kod',
      },
      {
        accessor: (row) => row.name,
        id: partnerBoxQueryKeys.name,
        Header: 'Ad',
      },
      {
        ...tableColumns.large,
        accessor: (row) => row.user?.name,
        id: partnerBoxQueryKeys.user.name,
        Header: 'Təhkim olunan',
      },
      {
        ...tableColumns.normal,
        accessor: (row) => row.declarationCount,
        id: partnerBoxQueryKeys.declarationCount,
        Cell: ({ cell: { value } }) => `${value} ədəd`,
        Header: 'Bağlama sayı',
        filterable: false,
      },
      {
        ...tableColumns.date,
        accessor: (row) => row.createdAt,
        id: partnerBoxQueryKeys.createdAt,
        Header: 'Yaradılma tarixi',
      },
    ],
    [],
  );

  return useMemo(() => {
    return [actionsColumn, ...baseColumns];
  }, [actionsColumn, baseColumns]);
};

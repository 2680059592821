import { useQuery, UseQueryOptions } from 'react-query';
import { useService } from '@core/inversify-react';

import { GetPartnerBoxByIdRepoType, IGetPartnerBoxByIdRepo } from '../repos';
import { IPartnerBox } from '../interfaces';

export const usePartnerBoxById = (id?: string, options?: UseQueryOptions<IPartnerBox, Error, IPartnerBox, string[]>) => {
  const getPartnerBoxByIdRepo = useService<IGetPartnerBoxByIdRepo>(GetPartnerBoxByIdRepoType);

  return useQuery<IPartnerBox, Error, IPartnerBox, string[]>(
    ['partner-boxes', id || ''],
    async ({ queryKey }) => {
      const [, id] = queryKey;
      const result = await getPartnerBoxByIdRepo.execute(id);

      if (result.status === 200) {
        return result.response;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};

import { inject } from 'inversify';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';

import { IPartnerBox } from '../interfaces';
import { PartnerBoxMapper } from '../mappers';

export type IGetPartnerBoxByIdRepoResponse = IRepoResponse<200, IPartnerBox> | IRepoResponse<400 | 500, string>;

export type IGetPartnerBoxByIdRepo = IRepo<[number | string], IGetPartnerBoxByIdRepoResponse>;

export const GetPartnerBoxByIdRepoType = Symbol.for('GetPartnerBoxByIdRepo');

@bind(GetPartnerBoxByIdRepoType)
export class GetPartnerBoxByIdRepo implements IGetPartnerBoxByIdRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute(id: number | string) {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/branchpartner/containers/info', { container_id: id }) });

      if (result.status === 200) {
        const data: IPartnerBox = PartnerBoxMapper.toDomain(result.data.data);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

import { inject } from 'inversify';
import { urlMaker } from '@core/utils';
import { IRepo, IRepoResponse } from '@core/repo';
import { HttpClientType } from '@core/http';
import type { IHttpClient } from '@core/http';
import { bind } from '@infra/container';

import { IBox } from '../interfaces';
import { BoxMapper } from '../mappers';

export type IGetMyBoxesRepoResponse = IRepoResponse<200, IBox[]> | IRepoResponse<400 | 500, string>;

export type IGetMyBoxesRepo = IRepo<[], IGetMyBoxesRepoResponse>;

export const GetMyBoxesRepoType = Symbol.for('GetMyBoxesRepo');

@bind(GetMyBoxesRepoType)
export class GetMyBoxesRepo implements IGetMyBoxesRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute() {
    try {
      const result = await this.httpClient.get({ url: urlMaker('/api/admin/mycontainers') });

      if (result.status === 200) {
        const data: IBox[] = result.data.map(BoxMapper.toDomain);

        return {
          status: 200 as 200,
          response: data,
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi',
      };
    }
  }
}

export const Constants = {
  DATE: 'YYYY-MM-DD',
  DATE_TIME: 'YYYY-MM-DD HH:mm:ss',
};

export const ErrorAudioUrls = {
  already_accepted: '/audio/already_accepted_error.mp3',
  different_branch_id: '/audio/different_branch_id_error.mp3',
  open_box_error: '/audio/different_branch_id_error.mp3',
  null_list: '/audio/null_list_error.wav',
  alert: '/audio/alert.mp3',
};

import { useService } from '@core/inversify-react';
import { GetPartnerBoxesRepoType, IPartnerBox, IGetPartnerBoxesRepo } from '@modules/partner-boxes';
import { useQuery, UseQueryOptions } from 'react-query';

export const usePartnerBoxes = (query = {}, options?: UseQueryOptions<IPartnerBox[], Error, IPartnerBox[], ['partner-boxes', any]>) => {
  const getPartnerBoxesRepo = useService<IGetPartnerBoxesRepo>(GetPartnerBoxesRepoType);

  return useQuery<IPartnerBox[], Error, IPartnerBox[], ['partner-boxes', any]>(
    ['partner-boxes', query],
    async ({ queryKey }) => {
      const [, query] = queryKey;

      const result = await getPartnerBoxesRepo.execute({ page: 1, per_page: 5000, ...query });

      if (result.status === 200) {
        return result.response.data;
      } else {
        throw new Error(result.response);
      }
    },
    options,
  );
};

import { NextTable } from '@core/table';

import { usePartnerBoxesTable } from '../hooks';
import { PartnerBoxesTableContext } from '../contexts';

export const PartnerBoxesTable = () => {
  const { columns } = usePartnerBoxesTable();

  return <NextTable context={PartnerBoxesTableContext} columns={columns} />;
};

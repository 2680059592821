import { inject } from 'inversify';
import { HttpClientType, snakeToCamel } from '@core/http';
import { IRepo, IRepoResponse } from '@core/repo';
import { urlMaker } from '@core/utils';
import { bind } from '@infra/container';
import type { IHttpClient } from '@core/http';

export type ICloseBoxRepoResponse = IRepoResponse<200> | IRepoResponse<422, Record<string, any>> | IRepoResponse<400 | 500, string>;

export type ICloseBoxRepo = IRepo<[{ trackCodes: string[]; containerId: string }], ICloseBoxRepoResponse>;

export const CloseBoxRepoType = Symbol.for('CloseBox');

@bind(CloseBoxRepoType)
export class CloseBoxRepo implements ICloseBoxRepo {
  constructor(@inject(HttpClientType) private readonly httpClient: IHttpClient) {}

  async execute({ trackCodes, containerId }) {
    try {
      const result = await this.httpClient.post({ url: urlMaker('/api/admin/containers/close', { track_code: trackCodes, requires_declaration_container_id: containerId }) });

      if (result.status === 200) {
        return {
          status: 200 as 200,
          response: null,
        };
      } else if (result.status === 422 || result.status === 400) {
        return {
          status: 422 as 422,
          response: snakeToCamel(result.data.errors),
        };
      } else {
        return {
          status: 400 as 400,
          response: 'Məlumatlar əldə edilə bilmədi',
        };
      }
    } catch (e) {
      return {
        status: 500 as 500,
        response: 'Şəbəkə ilə əlaqə qurula bilmədi.',
      };
    }
  }
}

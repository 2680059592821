import { IPartnerBox, IPartnerBoxPersistence } from '../interfaces';

export class PartnerBoxMapper {
  public static toDomain(container: IPartnerBoxPersistence): IPartnerBox {
    return {
      id: container.id,
      name: container.container_name,
      branch: container.branch_id
        ? {
            id: container.branch_id,
            name: container.branch_name ?? '',
          }
        : null,
      user:
        !!container.user_id && container.user_name
          ? {
              id: container.user_id,
              name: container.user_name,
            }
          : null,
      declarationCount: container.declaration_count,
      createdAt: container.created_at,
    };
  }
}

import React, { FC } from 'react';
import { useCloseModal } from '@core/router';
import { generatePath, useParams } from 'react-router-dom';

import { CreatePartnerBoxProvider } from '../contexts';
import { CreatePartnerBox } from '../containers';

export const CreatePartnerBoxPage: FC = () => {
  const closeModal = useCloseModal();
  const { id } = useParams<{ id?: string }>();

  const onClose = React.useCallback(() => {
    if (id) {
      closeModal(generatePath('/@next/partner-boxes/:id', { id }));
    } else {
      closeModal('/@next/partner-boxes');
    }
  }, [closeModal, id]);

  return (
    <CreatePartnerBoxProvider id={id} onCancel={onClose} onSucceed={onClose}>
      <CreatePartnerBox />
    </CreatePartnerBoxProvider>
  );
};

import { FormErrors } from '@core/form';

import { CreatePartnerBoxDto, CreatePartnerBoxDtoPersistence } from '../dtos';
import { IPartnerBox } from '../interfaces';

export class CreatePartnerBoxMapper {
  public static toDomain(box: IPartnerBox): CreatePartnerBoxDto {
    return {
      id: box.id.toString(),
      name: box.name,
    };
  }

  public static toPersistence(box: CreatePartnerBoxDto): CreatePartnerBoxDtoPersistence {
    return {
      container_id: box.id,
      container_name: box.name,
    };
  }
}

export class CreatePartnerBoxErrorsMapper {
  public static toDomain(errors): FormErrors<CreatePartnerBoxDto> {
    return {
      id: errors.id?.join('. '),
      name: errors.container_name?.join('. '),
    };
  }
}

import React from 'react';
import { ActionBar } from '@core/ui';
import * as Icons from '@ant-design/icons';

import { HeaderButton, HeadPortal } from '@modules/layout';

import { usePartnerBoxesActionBar } from '../hooks';
import { useAuth } from '@modules/auth';

export const PartnerBoxesActionBar = () => {
  const { create, reset, fetch } = usePartnerBoxesActionBar();
  const { can } = useAuth();

  return (
    <HeadPortal>
      <ActionBar>
        {can('branch_manager') && (
          <HeaderButton onClick={create} icon={<Icons.PlusCircleOutlined />}>
            Yeni
          </HeaderButton>
        )}
        <HeaderButton onClick={fetch} icon={<Icons.ReloadOutlined />}>
          Yenilə
        </HeaderButton>
        <HeaderButton onClick={reset} icon={<Icons.ClearOutlined />}>
          Sıfırla
        </HeaderButton>
      </ActionBar>
    </HeadPortal>
  );
};

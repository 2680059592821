import React, { FC, useCallback, useMemo, useState } from 'react';
import { message, Modal, Row, Select } from 'antd';
import { useBoxes } from '@modules/boxes';
import { filterOption } from '@core/form';
import { usePartnerBoxes } from '@modules/partner-boxes';

export const BoxSelection: FC<{ onClose: () => void; onOk: (boxId: string) => void; visible: boolean; branchId: string | undefined; isPartner?: boolean }> = ({
  onClose,
  onOk,
  visible,
  branchId,
  isPartner = false,
}) => {
  const [boxId, setBoxId] = useState<string | undefined>(undefined);
  const adminBoxes = useBoxes({}, { enabled: !isPartner });
  const partnerBoxes = usePartnerBoxes({}, { enabled: isPartner });

  const boxes = useMemo(() => (isPartner ? partnerBoxes : adminBoxes), [adminBoxes, partnerBoxes, isPartner]);

  const onSelectBox = useCallback((value) => setBoxId(value), []);
  const filteredBoxes = useMemo(() => boxes.data?.filter((box) => box.branch?.id.toString() === branchId), [boxes.data, branchId]);

  const onSubmitHandler = useCallback(() => {
    if (!boxId) return message.error('Yeşik seçin.');
    onOk(boxId);
    onClose();
  }, [onOk, onClose, boxId]);

  return (
    <Modal visible={visible} width={576} onOk={onSubmitHandler} onCancel={onClose} title='Yeşik seçimi'>
      <Row gutter={[12, 12]}>
        <Select filterOption={filterOption} onSelect={onSelectBox} showSearch style={{ width: '100%' }} placeholder='Yeşik seçin'>
          {filteredBoxes?.map((item) => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Row>
    </Modal>
  );
};

import { useCallback, useContext, useMemo } from 'react';
import { useService } from '@core/inversify-react';
import { FormikHelpers } from 'formik';
import { message } from 'antd';
import { useBus } from '@core/event-bus';

import { usePartnerBoxById } from './';
import { CreatePartnerBoxDto } from '../dtos';
import { CreatePartnerBoxMapper } from '../mappers';
import { CreatePartnerBoxContext } from '../contexts';
import { CreatePartnerBoxRepo, CreatePartnerBoxRepoType } from '../repos';

export const useCreatePartnerBox = () => {
  const { id, onSucceed } = useContext(CreatePartnerBoxContext);
  const order = usePartnerBoxById(id, { enabled: !!id });

  const createPartnerBoxRepo = useService<CreatePartnerBoxRepo>(CreatePartnerBoxRepoType);
  const eventBus = useBus();

  const initialValues = useMemo<CreatePartnerBoxDto | undefined>(() => {
    if (id) {
      return order.data ? CreatePartnerBoxMapper.toDomain(order.data) : undefined;
    }
    return { name: '' };
  }, [id, order.data]);

  const onSubmit = useCallback(
    async (values: CreatePartnerBoxDto, helpers: FormikHelpers<CreatePartnerBoxDto>) => {
      helpers.setStatus(null);
      const result = await createPartnerBoxRepo.execute(values, id || undefined);

      if (result.status === 200) {
        if (!id) {
          eventBus.publish({ type: '@partner-boxes/create/succeed' });
        } else {
          eventBus.publish({ type: '@partner-boxes/update/succeed' });
        }

        onSucceed?.();
      } else if (result.status === 422) {
        helpers.setErrors(result.response);
      } else {
        message.error(result.response);
      }
    },
    [createPartnerBoxRepo, eventBus, id, onSucceed],
  );

  return { onSubmit, initialValues };
};
